import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import axios from "../../axios";
import { CryptoPayment } from "../../components";
import { fetchRemoveOrder } from "../../redux/slices/order";
import { OrderSkeleton } from "./Skeleton";
import styles from "./Checkout.module.scss";
import { WayForPayPayment } from "../../components/WayForPayPayment";

export const Checkout = () => {
  const [orders, setOrders] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const userResponse = await axios.get(`/auth/me`);
        const response = await axios.get(
          `/orders/user/${userResponse.data._id}`
        );

        // Filter orders to display only those with status 'Pending'
        const filteredOrders = response.data.filter(
          (order) => order.statusId === 1
        );

        setOrders(filteredOrders);
        calculateTotalAmount(filteredOrders); // Calculate total amount
        setLoading(false); // Set loading to false after fetching
        if (filteredOrders.length === 0) {
          setIsEmpty(true);
        }
      } catch (error) {
        console.log(error);
        setLoading(false); // Set loading to false on error
        setIsEmpty(true);
      }
    };

    fetchOrders();
  }, []);

  const calculateTotalAmount = (orders) => {
    if (!orders) return 0;

    let total = 0;
    orders.forEach((order) => {
      total += order.totalAmount;
    });
    setTotalAmount(total);
  };

  const handleRadioChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const onClickRemove = (id) => {
    if (window.confirm("Remove?")) {
      dispatch(fetchRemoveOrder(id));
      window.location.reload();
    }
  };

  if (!window.localStorage.getItem("token")) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <OrderSkeleton />;
  }

  if (isEmpty) {
    return (
      <main
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h1 className="title">{t("card.empty")}</h1>
      </main>
    );
  }

  return (
    <main>
      <TableContainer component={Paper} className={styles.table}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("card.serv")}</TableCell>
              <TableCell>{t("card.term")}</TableCell>
              <TableCell>{t("card.price")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order._id}>
                <TableCell>
                  <img
                    src={`${process.env.REACT_APP_API_URL}${order.product.imageUrl}`}
                    alt=""
                    className={styles.image}
                  />
                </TableCell>
                <TableCell>
                  <span className={styles.price}>{order.product.title}</span>{" "}
                  <br /> {order.product.squ}
                </TableCell>
                <TableCell>
                  {order.totalAmount / order.product.price} {t("card.month")}
                </TableCell>
                <TableCell className={styles.price}>
                  {order.totalAmount}$
                </TableCell>
                <TableCell>
                  <DeleteForeverSharpIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickRemove(order._id)}
                  ></DeleteForeverSharpIcon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p className={`${styles.greenBg} ${"greenBg"}`}>{t("card.avail")}</p>
      <h2 className="title">{t("card.data")}</h2>
      <RadioGroup value={paymentMethod} onChange={handleRadioChange}>
        <FormControlLabel
          value="wayforpay"
          control={<Radio />}
          label={t("card.liq")}
        />
        <FormControlLabel
          value="crypto"
          control={<Radio />}
          label={t("card.crypto")}
        />
      </RadioGroup>
      <p className={styles.policy}>{t("card.approve")}</p>
      {paymentMethod === "wayforpay" && (
        <WayForPayPayment amount={totalAmount} orderId={orders[0]._id} />
      )}
      {paymentMethod === "crypto" && (
        <CryptoPayment amount={totalAmount} orderId={orders[0]._id} />
      )}
      {/* <div>Загальна сума: {totalAmount}</div> */}
    </main>
  );
};
