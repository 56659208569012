import React, { useEffect, useState } from "react";
import {
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import axios from "../axios";
import Navbar from "../components/Navbar";
import icon from "../assets/images/orders-icon.svg";
import { selectIsAdmin } from "../redux/slices/auth";
import { MyButton } from "../components/MyButton";
import styles from "./Me/Me.module.scss";
import Instruction from "../components/Instruction";
import ChangeOrderInfo from "../components/ChangeOrderInfo";

export const Order = () => {
  const isAdmin = useSelector(selectIsAdmin);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(true);

  useEffect(() => {
    const fetchData = async (curUserId) => {
      try {
        const res = await axios.get(`/orders/${id}`);
        setData(res.data);

        if (!isAdmin && curUserId !== res.data.user._id) {
          navigate("/");
        } else {
          setLogin(res.data.login);
          setPassword(res.data.password);
        }
      } catch (err) {
        alert("ERROR");
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchUser = async () => {
      try {
        const userResponse = await axios.get(`/auth/me`);
        const currentUserId = userResponse.data._id;
        fetchData(currentUserId);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      }
    };

    if (window.localStorage.getItem("token")) {
      fetchUser();
    } else {
      setIsLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    if (!isAdmin || (data && data.status === "Finished")) {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [isAdmin, data]);

  const onSubmit = async () => {
    try {
      if (!login || !password) {
        alert("Please fill in both login and password fields");
        return;
      }

      const fields = {
        login,
        password,
      };

      await axios.patch(`/orders/${data._id}`, fields);

      window.location.reload();
    } catch (error) {
      console.log(error);
      alert("Ошибка обновления заказа");
    }
  };

  const goToPreviousPage = () => {
    window.history.back();
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  console.log(data);

  return (
    <main>
      <div className={styles.wrapp}>
        {!isAdmin && (
          <div className={`${styles.item} ${styles.navbar}`}>
            <Navbar />
          </div>
        )}
        <div className={styles.item}>
          <h2 className={styles.title} style={{ flexWrap: "wrap" }}>
            <img src={icon} alt="" className={styles.icon} />{" "}
            {data.product.title}
            <tr />({data._id})
          </h2>
          <TableContainer component={Paper} className={styles.table}>
            <Table sx={{ minWidth: 650 }} aria-label="order table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("order.service")}</TableCell>
                  <TableCell>{t("order.availableTo")}</TableCell>
                  <TableCell>{t("order.status")}</TableCell>
                  <TableCell>{t("order.diy")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={data._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{data.product.title}</TableCell>
                  <TableCell>
                    {new Date(data.availableTo).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    style={
                      data.statusId === 3
                        ? { color: "#00B528" }
                        : data.statusId === 5
                        ? { color: "rgb(189 108 0)" }
                        : data.statusId === 2
                        ? { color: "#7400D1" }
                        : data.statusId === 4
                        ? { color: "#F10E00" }
                        : {}
                    }
                  >
                    {data.status}
                  </TableCell>
                  <TableCell>
                    {data.status === "Pending" && !isAdmin ? (
                      <Link to={`/checkout`}>
                        <MyButton variant="dark">{t("order.diy")}</MyButton>
                      </Link>
                    ) : (
                      <Link to={`/products/${data.product.slug}`}>
                        <MyButton variant="dark">{t("order.goTo")}</MyButton>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {!isAdmin && data.statusId === 5 && (
            <p style={{ margin: "20px", color: "red" }}>
              Unfortunately, your payment was incomplete or there was a problem
              with the payment. To resolve this issue, please contact our
              technical support. You can do so by clicking on the following
              link:{" "}
              <a href="/faq" style={{ textDecoration: "underline" }}>
                FAQs
              </a>
              .
            </p>
          )}

          {!isAdmin && data.statusId === 3 && <Instruction />}

          {(data.status === "Success" || isAdmin) && (
            <Paper style={{ marginTop: 30 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", marginBottom: 20 }}
                  className={styles.btns}
                >
                  <TextField
                    value={login}
                    variant="filled"
                    label="Login"
                    fullWidth
                    onChange={(e) => setLogin(e.target.value)}
                    style={{ marginRight: 10 }}
                    InputProps={{ readOnly: isReadOnly }}
                  />
                  <TextField
                    value={password}
                    variant="filled"
                    label="Password"
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{ readOnly: isReadOnly }}
                  />
                </div>
              </div>
              {!isReadOnly && (
                <MyButton
                  onClick={onSubmit}
                  variant="dark"
                  style={{ marginBottom: 20 }}
                >
                  Edit
                </MyButton>
              )}
            </Paper>
          )}

          <MyButton variant="grey" onClick={goToPreviousPage}>
            {t("order.myServices")}
          </MyButton>
        </div>
      </div>
    </main>
  );
};
