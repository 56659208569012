import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import userIcon from "../../assets/images/user.svg";
import { MyButton } from "../MyButton";
import styles from "./Footer.module.scss";
import logo from "../../assets/images/logo.svg";
import mkey from "../../assets/images/mkey.svg";
import { selectIsAuth, logout, selectIsAdmin } from "../../redux/slices/auth";
import Navigation from "../Navigation";
import { phone, email } from "../../mkey_settings";

export const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isAuth = useSelector(selectIsAuth);
  const isAdmin = useSelector(selectIsAdmin);
  const user = useSelector((state) => state.auth.data);

  const handleLogout = () => {
    if (window.confirm("Exit?")) {
      dispatch(logout());
      window.localStorage.removeItem("token");
      navigate("/");
    }
  };

  const getAuthButtons = () => {
    if (isAuth && isAdmin) {
      return (
        <>
          <Link key="admin-link" to="/admin">
            <MyButton variant="dark">{t("common.adminPanel")}</MyButton>
          </Link>
          <MyButton onClick={handleLogout} variant="grey">
            {t("common.exit")}
          </MyButton>
        </>
      );
    } else if (isAuth) {
      return (
        <>
          <MyButton variant="dark">
            <Link key="me-link" to="/me" className={styles.mybtn}>
              <img src={userIcon} alt="user" className={styles.user} />
              {t("common.account")}
            </Link>
          </MyButton>
          <MyButton onClick={handleLogout} variant="grey">
            {t("common.exit")}
          </MyButton>
        </>
      );
    } else {
      return (
        <>
          <Link key="login-link" to="/login">
            <MyButton variant="dark">{t("common.login")}</MyButton>
          </Link>
          <Link key="register-link" to="/register">
            <MyButton variant="grey">{t("common.register")}</MyButton>
          </Link>
        </>
      );
    }
  };

  const menu2 = {
    [t("header.contact")]: "/contacts",
    FAQ: "/faq",
    [t("header.blog")]: "/blog",
    [t("offerContract.title")]: "/offer-contract",
  };

  return (
    <>
      <div className={styles.root}>
        <div className="container">
          <div className={styles.inner}>
            <div className={styles.logo__wrapp}>
              <Link className={styles.logo} to="/">
                <img src={logo} />
              </Link>
              {/* <div className={styles.social}>
                <p>{t("common.phone")}:</p>
                <a href={`tel:${phone}`}>{phone}</a>
              </div> */}
              <div className={styles.social}>
                <p>Email:</p>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
              <div className={styles.social}>
                <p>{t("footer.address")}:</p>
                <a>
                  Ukraine, 07400, Kyiv region, city of Brovary, Brovary
                  district, street Moskalenko Serhiia, building 49, apartment
                  295 Code of EDRPOU 45335992
                </a>
              </div>
            </div>
            <Navigation menu={null} margin={10} isProduct={true} />
            <Navigation menu={menu2} margin={10} />
            <div className={styles.buttons}>{getAuthButtons()}</div>
          </div>
        </div>
      </div>
      <div className={styles.footer__bootom}>
        <div className="container">
          <div className={styles.inner}>
            <Link to="/privacy-policy" className={styles.grey}>
              {t("footer.policy")}
            </Link>
            <p className={styles.grey}>©2024 macosstrong</p>
            <a href="https://mkeymedia.com/" className={styles.mkey}>
              <p className={styles.grey}>{t("footer.devBy")}</p>
              <img src={mkey} alt="mkey" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
